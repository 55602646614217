import React, { useState, useEffect, useContext } from "react";
import Logout from "../auth/Logout";
import { SnippetData } from "../../data/types";
import { getSnippetByName } from "../../data/data";
import { auth } from "../../config/firebase";
import "./layout.css";
import Center from "../utils/Center";
import AuthContext from "../../context/auth-context";

interface Props {
    children?: React.ReactNode;
}

const Header = ({ children }: Props) => {

    //const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [headerSnippet, setHeaderSnippet] = useState<SnippetData>();

    useEffect(() => {
        getSnippetByName("Header")
            .then((snippetData) => {
                setHeaderSnippet(snippetData);
                // console.log(snippetData);
            })
            .catch((e) => {
                setHeaderSnippet(undefined);
            });

        //getMenu().then(setMenuItems);
    }, []);

    const authCtx = useContext(AuthContext);

    return (
        <div className="header">
            <Center height={"auto"}>
            <div className="headerContent" dangerouslySetInnerHTML={{__html: headerSnippet?.pagecontent || ""}} />
            {/*menuItems.map((menuItem) => (<div key={menuItem.id}><Link to={menuItem.url}>{menuItem.text}</Link></div>))*/}
            {authCtx.isFacultyLoggedIn || authCtx.isStudentLoggedIn ? <Logout/> : null}
            </Center>
        </div>
    );
};

export default Header;
