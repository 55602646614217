import { useState, useEffect } from "react";
import { getStoragePath } from "../../config/firebase";
import { VideoData } from "../../data/types";
import './VideoBlock.css';
import { DocumentReference } from "firebase/firestore/lite";
import { getVideoByRef } from "../../data/data";

interface VideoBlockProps {
    videoReference: DocumentReference<VideoData>;
}

const VideoBlock = ({ videoReference }: VideoBlockProps) => {
    const [videoData, setVideoData] = useState<VideoData>();
    const [clicked, setClicked] = useState(false);
    const [thumbURL, setThumbURL] = useState<string>();

    useEffect(() => {
        getVideoByRef(videoReference)
            .then(v => {
                setVideoData(v);
            });
    }, [videoReference]);

    useEffect(() => {
        if (videoData) {
            getStoragePath(videoData.thumbnail)
                .then(p => setThumbURL(p))
                .catch(e => setThumbURL(undefined));
        }
    }, [videoData]);

    return videoData ? (<div className="videoBlock">
        <h3>{videoData.title}</h3>
        {clicked || !videoData.thumbnail ?
            (<iframe
                className="embedVideo"
                src={`https://www.youtube.com/embed/${videoData.youtubeId}${clicked ? "?autoplay=1" : ""}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}></iframe>)
            :
            (<div className="thumbnailImage" onClick={() => setClicked(true)}>
                {thumbURL ? <img src={thumbURL} /> : null}
            </div>)
        }
    </div>) : null;
};

export default VideoBlock;
