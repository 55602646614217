import { useState, useContext } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import Center from "../utils/Center";
import { checkStudentPassword } from "../../data/data";
import AuthContext from "../../context/auth-context";

interface Props { }

const AuthContainer = (props: Props) => {
  const navigate = useNavigate();
  const [studentcode,setStudentcode] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [studentErrorMessage, setStudentErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const ctx = useContext(AuthContext);

  const studentSignIn = () => {
    setDisabled(true);
    checkStudentPassword(studentcode)
      .then((passwordAccepted) => {
        setDisabled(false);
        if (passwordAccepted) {
          ctx.setStudentPassword(studentcode);
          navigate("/p/student-videos");
        } else {
          setStudentErrorMessage("Password incorrect");
        }
      })
      .catch((error) => {
        setErrorMessage("Password incorrect");
        setDisabled(false);
      });
  };

  const signIn = () => {
    setDisabled(true);
    signInWithEmailAndPassword(auth, email, pass)
      .then(() => {
        setDisabled(false);
        navigate("/p/faculty-home");
      })
      .catch((error) => {
        setErrorMessage(error.code + ": " + error.message);
        setDisabled(false);
      });
  };

  return (<>
    <Box
    display={"flex"}
    alignItems={"center"}
    flexDirection={"column"}
    boxShadow={2}
    margin={3}
    padding={3}
  >
      <form>
        <Stack direction="column" spacing={2}>
          <div>Student Login</div>
          <TextField
            required
            id="studentcode"
            label="Code"
            value={studentcode}
            onChange={(e) => setStudentcode(e.target.value)}
          />
          <Button variant="contained" onClick={studentSignIn} disabled={disabled}>Login</Button>
        </Stack>
      </form>
      <Typography sx={{ mt: 2 }} color={"red"}>
        {studentErrorMessage}
      </Typography>
    </Box><Box
    display={"flex"}
    alignItems={"center"}
    flexDirection={"column"}
    boxShadow={2}
    margin={3}
    padding={3}
  >
      <form>
        <Stack direction="column" spacing={2}>
          <div>Faculty/Staff Login</div>
          <TextField
            required
            id="username"
            label="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
          <Button variant="contained" onClick={signIn} disabled={disabled}>Login</Button>
        </Stack>
      </form>
      <Typography sx={{ mt: 2 }} color={"red"}>
        {errorMessage}
      </Typography>
    </Box>
    </>);
};

export default AuthContainer;
