import { useEffect, useState, useContext } from "react";
import { getContentByUrl } from "../data/data";
import { useParams } from "react-router-dom";
import { ContentData } from "../data/types";
import './Page.css';
import VideoBlock from "../components/video/VideoBlock";
import AuthContext from "../context/auth-context";

const Page = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState<ContentData>();

    const authCtx = useContext(AuthContext);

    useEffect(() => {
        setLoading(true);
        console.log(id);
        if (id) {
            getContentByUrl(id)
                .then((contentData) => {
                    if ( (contentData.studentAuthRequired && ( !authCtx.isStudentLoggedIn && !authCtx.isFacultyLoggedIn)) 
                        || (contentData.staffAuthRequired && !authCtx.isFacultyLoggedIn) ) {
                        // go to login
                    } else {
                        setLoading(false);
                        setContent(contentData);
                    }
                })
                .catch((e) => {
                    setContent(undefined);
                });
        } else {
            setContent(undefined);
        }
    }, [id, authCtx]);

    useEffect(() => {
        document.title = loading ? "" : (content?.title || "");
    }, [content?.title, loading]);

    return loading ? (
        <div>Loading...</div>)
        : (<div className="pageBody">
            <div className="pageText" dangerouslySetInnerHTML={{__html: content?.pagecontent1 || ""}} />
            <div className="videos">
            {content?.videos?.map(v => (<VideoBlock key={v.id} videoReference={v}/>))}
            </div>
            <div className="pageText" dangerouslySetInnerHTML={{__html: content?.pagecontent2 || ""}} />
        </div>);
};

export default Page;
