import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { auth, analytics } from "./config/firebase";
import routes from "./config/routes";
import AuthChecker from "./components/auth/AuthChecker";
import Layout from "./components/layout";
import AuthContext from "./context/auth-context";
import RoutePageView from "./components/router/RoutePageView";

function App() {
  const [studentPassword, setStudentPassword] = useState("");
  const [facultyId, setFacultyId] = useState("");
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setFacultyId(user.uid);
        console.info("User detected.");
      } else {
        setFacultyId("");
        console.info("No user detected");
      }
    });
  }, []);

  useEffect(() => {
    const studentPW = sessionStorage.getItem("studentPassword");
    if (studentPW) {
      setStudentPassword(studentPW);
    }
  }, [])

  const setStudentPasswordInSession = (password: string) => {
    setStudentPassword(password);
    sessionStorage.setItem("studentPassword", password);
    logEvent(analytics, 'student_login', { code: password });
  }

  return (
    <div>
      <AuthContext.Provider value={{ isStudentLoggedIn: studentPassword !== "", isFacultyLoggedIn: facultyId !== "", setStudentPassword: setStudentPasswordInSession }}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <RoutePageView>
            <Layout>
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      route.protected ? (
                        <AuthChecker>
                          <route.component />
                        </AuthChecker>
                      ) : (
                        <route.component />
                      )
                    }
                  />
                ))}
              </Routes>
            </Layout>
          </RoutePageView>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
