import React, { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { analytics } from "../../config/firebase";

interface Props {
    children: React.ReactNode;
}

const RoutePageView = ({ children }: Props) => {
    const loc = useLocation();

    useEffect(() => {
        logEvent(analytics, "page_view", {
            page_location: loc.pathname,
        })
    }, [loc]);
    return <>{children}</>;
};

export default RoutePageView;
