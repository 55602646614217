import React from "react";
import Header from "./Header";
import Footer from "./Footer";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {

  return (
    <>
    <Header />
    <div >
      {children}
    </div>
    <Footer />
    </>
  );
};

export default Layout;
