import React, { useState, useEffect } from "react";
import { SnippetData } from "../../data/types";
import { getSnippetByName } from "../../data/data";

interface Props {
  children?: React.ReactNode;
}

const Footer = ({ children }: Props) => {

  //const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [footerSnippet, setFooterSnippet] = useState<SnippetData>();

  useEffect(() => {
    getSnippetByName("Footer")
      .then((snippetData) => {
        setFooterSnippet(snippetData);
        // console.log(snippetData);
      })
      .catch((e) => {
        setFooterSnippet(undefined);
      });

    //getMenu().then(setMenuItems);
  }, []);

  return (
    <div className="footer">
      <div className="footerContent" dangerouslySetInnerHTML={{ __html: footerSnippet?.pagecontent || "" }} />
      {/*menuItems.map((menuItem) => (<div key={menuItem.id}><Link to={menuItem.url}>{menuItem.text}</Link></div>))*/}
    </div>
  );
};

export default Footer;
