import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase.config";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
export const Providers = { google: new GoogleAuthProvider() };
const storage = getStorage(Firebase);
//const storageRef = ref(storage);
export const getStoragePath = async (filename:string) => {
    const fileRef = ref(storage,filename);
    return await getDownloadURL(fileRef);
}

export const analytics = getAnalytics(Firebase);