import React, { useEffect, useState } from "react";
import AuthContainer from "../components/auth/AuthContainer";
import Center from "../components/utils/Center";
import { SnippetData } from "../data/types";
import { getSnippetByName } from "../data/data";

const Login = () => {
  const [homeSnippet, setHomeSnippet] = useState<SnippetData>();

  useEffect(() => {
    document.title = "Active Study Skills Home";
    getSnippetByName("Home Description")
      .then((snippetData) => {
        setHomeSnippet(snippetData);
        // console.log(snippetData);
      })
      .catch((e) => {
        setHomeSnippet(undefined);
      });
  }, []);

  return (
    <Center height={"auto"}>
      <div className="homeContent" dangerouslySetInnerHTML={{ __html: homeSnippet?.pagecontent || "" }} />
      <AuthContainer />
    </Center>
  );
};

export default Login;
