import React, { useState, useContext } from "react";
import { Button } from "@mui/material";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import AuthContext from "../../context/auth-context";

interface Props {
  navigateTo?: string;
}

const Logout = ({ navigateTo = "/login" }: Props) => {
  const [disabled, setDisabled] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const logout = () => {
    setDisabled(true);
    if (authCtx.isFacultyLoggedIn) {
      signOut(auth)
        .then(() => {
          navigate(navigateTo);
        })
        .catch((error) => {
          console.error(error);
          setDisabled(false);
        });
    } else if (authCtx.isStudentLoggedIn) {
      authCtx.setStudentPassword("");
      navigate(navigateTo);
    }
  };

  return (
    <div>
      <Button disabled={disabled} onClick={logout}>
        Logout
      </Button>
    </div>
  );
};

export default Logout;
