import { getFirestore, collection, getDocs, getDoc, FirestoreDataConverter, DocumentData, WithFieldValue, QueryDocumentSnapshot, doc, query, where, DocumentReference } from 'firebase/firestore/lite';
import { Firebase } from '../config/firebase';
import { ContentData, SnippetData, MenuItem, VideoData, StudentPassword } from './types';

const db = getFirestore(Firebase);

class ContentDataConverter implements FirestoreDataConverter<ContentData> {
    toFirestore(modelObject: WithFieldValue<ContentData>): DocumentData {
        return {
            name: modelObject.name,
            pagecontent1: modelObject.pagecontent1,
            pagecontent2: modelObject.pagecontent2,
            url: modelObject.url,
            lastupdate: modelObject.lastupdate,
        };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): ContentData {
        return {
            id: snapshot.id,
            name: snapshot.data().name,
            title: snapshot.data().title,
            pagecontent1: snapshot.data().pagecontent1,
            videos: snapshot.data().videos.map((ref:DocumentReference) => (ref.withConverter<VideoData>(videoConverter))),
            pagecontent2: snapshot.data().pagecontent2,
            url: snapshot.data().url,
            studentAuthRequired: snapshot.data().studentAuthRequired,
            staffAuthRequired: snapshot.data().staffAuthRequired,
            lastupdate: snapshot.data().lastupdate,
        };
    }
}

class SnippetDataConverter implements FirestoreDataConverter<SnippetData> {
    toFirestore(modelObject: WithFieldValue<SnippetData>): DocumentData {
        return {
            name: modelObject.name,
            pagecontent: modelObject.pagecontent,
            lastupdate: modelObject.lastupdate,
        };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): SnippetData {
        return {
            id: snapshot.id,
            name: snapshot.data().name,
            pagecontent: snapshot.data().pagecontent,
            lastupdate: snapshot.data().lastupdate,
        };
    }
}

class VideoDataConverter implements FirestoreDataConverter<VideoData> {
    toFirestore(modelObject: WithFieldValue<VideoData>): DocumentData {
        return {
            title: modelObject.title,
            youtubeId: modelObject.youtubeId,
            embedCode: modelObject.embedCode,
            thumbnail: modelObject.thumbnail,
        };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): VideoData {
        return {
            id: snapshot.id,
            title: snapshot.data().title,
            youtubeId: snapshot.data().youtubeId,
            embedCode: snapshot.data().embedCode,
            thumbnail: snapshot.data().thumbnail,
        };
    }
}

class MenuItemConverter implements FirestoreDataConverter<MenuItem> {
    toFirestore(modelObject: WithFieldValue<MenuItem>): DocumentData {
        return {
            text: modelObject.text,
            url: modelObject.url,
            auth: modelObject.auth,
            admin: modelObject.admin,
        };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): MenuItem {
        return {
            id: snapshot.id,
            text: snapshot.data().text,
            url: snapshot.data().url,
            auth: snapshot.data().auth,
            admin: snapshot.data().admin,
        }
    }
}

class StudentPasswordConverter implements FirestoreDataConverter<StudentPassword> {
    toFirestore(modelObject: WithFieldValue<StudentPassword>): DocumentData {
        return {
            name: modelObject.name,
            enabled: modelObject.enabled,
        };
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): StudentPassword {
        return {
            id: snapshot.id,
            name: snapshot.data().name,
            enabled: snapshot.data().enabled,
        }
    }
}

const contentConverter = new ContentDataConverter();
const snippetConverter = new SnippetDataConverter();
const menuConverter = new MenuItemConverter();
const videoConverter = new VideoDataConverter();
const studentPasswordConverter = new StudentPasswordConverter();

const getContent = async () => {
    const contentCol = collection(db, 'pages').withConverter<ContentData>(contentConverter);
    const contentSnapshot = await getDocs(contentCol);
    const contentList = contentSnapshot.docs.map(doc => doc.data());
    return contentList;
}

const getContentById = async (contentId: string) => {
    const docRef = doc(db,'pages',contentId).withConverter<ContentData>(contentConverter);
    //const contentCol = collection(db, 'content').withConverter<ContentData>(converter);
    const contentSnapshot = await getDoc(docRef);
    if ( contentSnapshot.exists() ) {
        return contentSnapshot.data();
    } else {
        throw new Error("Content not found");
    }
}

const getContentByUrl = async (url: string) => {
    const q = query(collection(db,'pages'), where("url","==",url)).withConverter<ContentData>(contentConverter);
    const contentSnapshot = await getDocs(q);
    if ( contentSnapshot.size === 1 ) {
        //console.log(contentSnapshot);
        return contentSnapshot.docs[0].data();
    } else {
        //console.log("error 1");
        throw new Error("Content not found");
    }
}

const getSnippetByName = async (name: string) => {
    const q = query(collection(db,'snippets'), where("name","==",name)).withConverter<SnippetData>(snippetConverter);
    const snippetSnapshot = await getDocs(q);
    if ( snippetSnapshot.size === 1 ) {
        //console.log(contentSnapshot);
        return snippetSnapshot.docs[0].data();
    } else {
        //console.log("error 1");
        throw new Error("Content not found");
    }
}

const getMenu = async () => {
    const menuCol = collection(db,'menuItems').withConverter<MenuItem>(menuConverter);
    const menuSnapshot = await getDocs(menuCol);
    const menuList = menuSnapshot.docs.map(doc => doc.data());
    return menuList;
}

const getVideoByRef = async (videoRef: DocumentReference<VideoData>) => {
    const vid = await getDoc(videoRef);
    if ( vid.exists() ) {
        return vid.data();
    } else {
        throw new Error("Video not found");
    }
}

const getVideosById = async (videoIds: string[]) => {
    const q = query(collection(db,'videos'), where("id","in",videoIds)).withConverter<VideoData>(videoConverter);
    const contentSnapshot = await getDocs(q);
    return contentSnapshot.docs.map(d => d.data());
}

const checkStudentPassword = async(studentpassword: string) => {
    const q = query(collection(db,'studentPassword'), where("name","==",studentpassword)).withConverter<StudentPassword>(studentPasswordConverter);
    const pwSnapshot = await getDocs(q);
    if ( pwSnapshot.size === 1 ) {
        return pwSnapshot.docs[0].data().enabled;
    } else {
        return false;
    }
}

export { getContent, getContentById, getContentByUrl, getSnippetByName, getMenu, getVideoByRef, getVideosById, checkStudentPassword };